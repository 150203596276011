/*
 * @Date: 2022-12-07 16:31:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-06 10:20:38
 * @FilePath: /shoplus-global-website-node/pages/index.tsx
 */
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { watchDom } from '@/watch'
import { getGoodsTrendChart, getGoodsTrend } from '@/service/index'
import { useEffect, useRef, useState } from 'react'
import Layout from '@/components/layout'
import { useWidth } from '@/utils/resize'
import dynamic from 'next/dynamic'
import { GetServerSideProps } from 'next'
import { setCookie, getCookie } from 'cookies-next'
import { getRecommendBlog, getPayDiscountInfo, getPayDiscountInfoUnToken } from '@/service/index'
import { useTranslation } from 'next-i18next'
import DiscountDrawer from '@/components/discountDrawer'

//pc
const PcAnli = dynamic(import('@home3/pc/anli'))
const Faqs = dynamic(import('@home/faqs'))
const PcBanner = dynamic(import('@home3/pc/banner'))
const PcReView = dynamic(import('@home3/pc/Reviews'))
const PcEcomTrend = dynamic(import('@home3/pc/ecomTrend'))
const PcInfluencers = dynamic(import('@home3/pc/Influencers'))
const PcFreestart = dynamic(import('@home3/pc/Freestart'))
const PcBusiness = dynamic(import('@home3/pc/Business'))
const Blogs = dynamic(import('@home3/pc/blogs'))
const TextImageList = dynamic(import('@home3/pc/TextImageList'))
import Text1Img from "@/public/images/home/TextImage/1-1.png";
import Text1Icon from "@/public/images/home/TextImage/1-2.png";
import Text2Icon from "@/public/images/home/TextImage/2-2.png";
import Text2Img from "@/public/images/home/TextImage/2-1.png";
import Text3Icon from "@/public/images/home/TextImage/3-2.png";
import Text3Img from "@/public/images/home/TextImage/3-1.png";
import SellerIcon1 from "@/public/images/home/TextImage/seller_icon1.png";
import SellerIcon2 from "@/public/images/home/TextImage/seller_icon2.png";
import SellerIcon3 from "@/public/images/home/TextImage/seller_icon3.png";
import SellerImg1 from "@/public/images/home/TextImage/seller_img1.png";
import SellerImg2 from "@/public/images/home/TextImage/seller_img2.png";
import SellerImg3 from "@/public/images/home/TextImage/seller_img3.png";

const Home: NextPage<{
  context: any
  productData: any
  trendData: any
  trendChart: any
  blogList: any
}> = (props) => {
  const router = useRouter()
  // const { locale } = router;
  const { width, display } = useWidth()
  const isPc = width >= 1024
  const dom: any = useRef()
  // const watchData: any = "";
  const [init, setInit] = useState(false)
  const [trendChart, setTrendChart] = useState(undefined)
  const [trendData, setTrendData] = useState(undefined)
  const { t, i18n } = useTranslation(['home'])
  let { context, blogList } = props

  const textImageList1 = [
    {
      id: 1,
      title: t('creatorTitle1'),
      desc: t('creatorDesc1'),
      icon: Text1Icon,
      img: Text1Img
    },
    {
      id: 2,
      title: t('creatorTitle2'),
      desc: t('creatorDesc2'),
      icon: Text2Icon,
      img: Text2Img
    },
    {
      id: 3,
      title: t('creatorTitle3'),
      desc: t('creatorDesc3'),
      icon: Text3Icon,
      img: Text3Img
    }
  ]

  const textImageList2 = [
    {
      id: 1,
      title: t('sellerTitle1'),
      desc: t('sellerDesc1'),
      icon: SellerIcon1,
      img: SellerImg1
    },
    {
      id: 2,
      title: t('sellerTitle2'),
      desc: t('sellerDesc2'),
      icon: SellerIcon2,
      img: SellerImg2
    },
    {
      id: 3,
      title: t('sellerTitle3'),
      desc: t('sellerDesc3'),
      icon: SellerIcon3,
      img: SellerImg3
    }
  ]


  useEffect(() => {
    //  TODO:  商品趋势图表
    let trendDataInfo: any = undefined
    let Trends: any = undefined
    let trendChartInfo: any = undefined
    if (!init) {
      const trendReq = async () => {
        trendChartInfo = await getGoodsTrendChart(context)
        Trends = await getGoodsTrend(context)
        setTrendChart(trendChartInfo)
        if (Trends && Trends.code == 0) {
          trendDataInfo = Trends.data
          setTrendData(trendDataInfo)
        }
        setInit(true)
      }
      trendReq()
    }
  }, [])

  return (
    <div>
      <Layout data={{ trendData, trendChart }} header={true} footer={true} isHome={true}>
        <Head>
          <link rel="canonical" href={`https://www.shoplus.net/`} />
          <title>{t('seo home title')}</title>
          <meta name="keywords" content={t('seo home keywords')}></meta>
          <meta name="description" content={t('seo home description')} />
        </Head>
        <div ref={dom} className="bg-[#fff]">
          <PcAnli info={props}></PcAnli>
          <TextImageList list={textImageList2} title={t('sellerTitle')} reverse={true} />
          <TextImageList list={textImageList1} title={t('creatorTitle')}/>
          {/*<PcEcomTrend />*/}
          {/*<PcInfluencers />*/}
          {/*<PcBusiness></PcBusiness>*/}
          <PcReView />
          <Blogs list={blogList}></Blogs>
          <Faqs />
          <PcFreestart></PcFreestart>
        </div>
      </Layout>
    </div>
  )
}
export const ActivityMatchCode = {
  NinePointNine: 'Basic9.9', // 9.9活动
  Nineteen: 'Basic19.9', // 19.9活动
  AllUsers: 'Basic98' // 适用所有人
}
export const getServerSideProps: GetServerSideProps = async (context) => {
  setCookie('LOCAL_UA', context.req.headers['user-agent'], {
    req: context.req,
    res: context.res
  })
  const { locale } = context
  const res = await getRecommendBlog({
    language: 'en',
    needContent: false,
    context: context,
    type: 0, // 0:博客
    pageSize: 4
  }).catch()

  let blogList = res?.code === 0 && res.data ? res.data : []

  const authorizedToken = context?.req?.cookies?.['authorized-token'] || '{}'
  const token = JSON.parse(authorizedToken)?.accessToken
  const isAuth = !!token
  const params = {
    systemId: 14,
    marketingType: 5,
    include_not_usable: true,
    deviceId: getCookie('BURIEDSDK_DEVICEID'),
    marketing_codes: `${ActivityMatchCode.NinePointNine},${ActivityMatchCode.Nineteen},${ActivityMatchCode.AllUsers}`
  }

  const fn = isAuth
    ? getPayDiscountInfo(params.systemId, params.marketingType, params.deviceId, token, params)
    : getPayDiscountInfoUnToken(params)

  const infoData = await fn

  let discountInfo = {}
  let bannerList: Array<any> = ['Banner', 'Banner2']

  if (infoData && infoData.data && infoData.data.length) {
    bannerList = ['Banner', 'Banner2', 'Activity']
    // 拿时间最晚的
    discountInfo =
      infoData.data
        .filter((item) => item?.usable)
        .sort((a, b) => {
          return b.usableEndTime - a.usableEndTime
        })[0] || {}
  }

  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['home', 'common', 'nav', 'footer'])),
      context: {
        locale,
        req: {
          headers: context.req.headers
        }
      },
      blogList,
      bannerList,
      discountInfo,
      infoData: infoData?.data || []
    }
  }
}
export default Home
